.news-card {
    display: inline-block;
    white-space: normal;
    overflow: hidden;
    // margin: 20px;
    width: calc(clamp(300px, 24vw, 600px));
    aspect-ratio: 1/1.1;
    background-color: rgba(255, 255, 255, 0.9);
    border: 5px solid var(--color-orange);
    box-sizing: border-box;
    border-radius: calc(60px * var(--scale-pixel-koef));
    user-select: none;
    -webkit-user-drag: none;

    & .news-photo {
        max-height: clamp(35%, 12vw, 40%);
        min-height: clamp(35%, 12vw, 40%);
        // min-height: 40%; // unknwn behavior
        & img {
            object-fit: cover;
            -o-object-fit: cover;
            object-position: center center;
            -o-object-position: center center;
            height: 100%;
            min-height: 100%;
            width: 100%;
            min-width: 100%;
            user-select: none;
            -webkit-user-drag: none;
        }
    }

    & .card-title {
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: end;

        & p {
            background: var(--color-orange);
            text-wrap: nowrap;
            font-size: calc(clamp(20px, 1.3vw, 35px) * var(--scale-pixel-koef));
            font-weight: 400;
            height: 2em;
            line-height: 1em;
            align-content: center;
            text-align: center;
            user-select: none;
            width: 100%;
            -webkit-user-drag: none;
        }
    }

    & .orange-gradient {
        position: relative;

        &::before {
            content: '';
            position: absolute;

            left: 0;
            bottom: -1px;
            right: 0;
            height: 30%;
            background: linear-gradient(
                    359.16deg,
                    rgba(193, 49, 0, 0.7) 0.72%,
                    rgba(0, 0, 0, 0) 101.11%
                )
                repeat left top;
        }
    }

    & .card-text {
        display: flex;
        height: 100%;
        width: 100%;
        padding-top: clamp(15px, 1vw, 25px);
        padding-inline: clamp(15px, 1vw, 25px);
        overflow: auto;
        text-align: left;
        user-select: none;
        font-size: calc(clamp(16px, 1vw, 30px) * var(--scale-pixel-koef));
        -webkit-user-drag: none;
    }

    & a {
        user-select: none;
        -webkit-user-drag: none;
    }

    & .card-about {
        width: 100%;
        padding-bottom: clamp(15px, 1vw, 25px);
        padding-inline: clamp(15px, 1vw, 25px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        & p {
            width: fit-content;
            display: inline;
            color: black;
            font-size: calc(clamp(16px, 0.9vw, 30px) * var(--scale-pixel-koef));
            transition: 0.2s linear;
        }

        & img {
            aspect-ratio: 1/1;
            height: clamp(40px, 3vw, 65px);
        }
    }

    & .card-about:hover p {
        color: var(--color-orange);
        transition: 0.3s linear;
    }

    & .news-mobile {
        height: 100%;
        width: 100%;
        display: none;
    }

    & .news-desktop {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 1100px) {
    .news-desktop {
        display: none;
    }

    .news-mobile {
        display: inherit;
    }

    // .card-title {
    //   p {
    //     background-color: unset;
    //     font-size: 35px;
    //   }

    // .news-card {
    //   margin: 15px 30px;
    // }
}

// @media (min-width: 1100px) {
//   .hidden {
//     display: none;
//   }

// }
.trunc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;

}
