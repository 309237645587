.page-section {
  position: absolute;
  background:
          linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
          url("../../../../src/components/assets/images/backgrounds/event1.png") no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.circle {
  margin: 90px 0 0 230px;
  width: 140px;
  height: 140px;
  border: 5px solid #ffffff;
  border-radius: 50%;
}

.link {
  text-decoration: none;
}

.navigation-bar {
  position: absolute;
  top: 150px;
  left: 50px;
  width: 100px;
  height: 751px;
  display: flex;
  justify-content: left;
}

.list-bar {
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: top;
}

.elem {
  margin-bottom: 117px;
  display: flex;
  align-items: center;
  line-height: 50px;
  justify-content: left;
}

.h-line {
  display: inline-block;
  height: 7px;
  width: 40px;
  background-color: white;
  border-radius: 0 3px 3px 0;
}

.v-line {
  display: inline-block;
  height: 100%;
  width: 7px;
  background-color: white;
  border-radius: 3px 0 0 3px;
}

.pointer {
  position: absolute;
  left: 35px;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #ff5c00;
  border: solid 5px white;
  border-radius: 50%;
  transition: .3s ease-out;
}

.title-1 {
  font-size: 60px;
}

.event-btn {
  width: 100%;
  height: 80px;
  border: none;
  border-radius: 50px;
  margin: 30px 0 30px 0;

  background-size: 300% 100%;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;

  background-image: linear-gradient(to right, #ff5c00, #e43603, #efc53b, #e85a19);
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
}

.next-btn, .prev-btn {
  background: transparent;
  border: solid 3px #ff5c00;
  width: 600px;

  &:hover {
    box-shadow: 0 4px 20px 0 #ff5c00;
  }
}

.prev-btn {
  display: none;
}

.event-btn:hover {
  background-position: 50% 0;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.dark-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  z-index: 100;
}

.sidebar {
  position: absolute;
  right: -800px;
  top: 0;
  width: 800px;
  height: 100%;
  background-color: #ff5c00;
  z-index: 100000;
  transition: ease-in-out .5s;

  & img {
    cursor: pointer;
  }
}

.sidebar-closed {
  right: 0;
  transition: ease-in-out .7s;
}

.team-board {
  display: flex;
  margin-top: 50px;
  height: 600px;
  width: 600px;
  overflow: auto;
  overflow-x: hidden;
}

.form {
  flex-shrink: 0;
  width: 450px;
  height: 55px;
  background-color: white;
  border-radius: 50px;
  border: none;
  text-align: left;
  font-size: 25px;
  padding-left: 30px;
  margin: 0 0 70px 50px;
}

.about-form {
  flex-shrink: 0;
  width: 600px;
  height: 55px;
  background-color: white;
  border-radius: 50px;
  border: none;
  text-align: left;
  font-size: 25px;
  padding-left: 30px;
  margin: 60px 0 0 50px;
}

.member {
  margin: 40px 0 0 50px;
}

.outer-box {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 200px;
  width: 600px;
  background-color: white;
  border-radius: 30px;
  margin-top: 40px;
  margin-left: 50px;
}

.member-board {
  margin: 0;
  height: 160px;
  font-size: 25px;
  text-align: center;
  width: 560px;

  & p {
    color: #6C6C6C;
  }
}

.add-form {
  align-content: center;
  background: white;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  border: none;
  margin: 50px 0 0 200px;
}

/* Works on Firefox */
.team-scrollbar {
  scrollbar-color: white #ff5c00;
}
.member-scrollbar {
  scrollbar-color: white #ff5c00;
}

/* Works on Chrome, Edge, and Safari */
.team-scrollbar::-webkit-scrollbar {
  width: 30px;
}
.member-scrollbar::-webkit-scrollbar {
  width: 15px;
}

.team-scrollbar::-webkit-scrollbar-track {
  background: white;
  border-radius: 50px;
}
.member-scrollbar::-webkit-scrollbar-track {
  background: #ff5c00;
  border-radius: 50px;
}

.team-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ff5c00;
  border-radius: 20px;
  border: 5px solid white;
}
.member-scrollbar::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: 5px solid #ff5c00;
}

#v2, #v3, #v4, #v5, #v6, #v7 {
  display: none;
}