.page-section {
    position: absolute;
    background:linear-gradient(to bottom, transparent, 95%, #e43603), url("../../../../../src/components/assets/images/backgrounds/event1black.png") no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
}


.title {
    position: absolute;
    text-align: center;
    font-size: 80px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    user-select: text;
}

.text-main1{
    white-space: nowrap;
    position:absolute;
    font-size: 28px;
    font-weight: 400;
    line-height: 60px;
    text-align: center;  
    color:rgb(255, 255, 255);
    left: 60px;  
    top: 80px;
}

.link-place{
    color:rgb(255, 255, 255);
}

.button-main2{
    position:absolute;
    top:95px;
    left: 100px;
    border: solid 2px #e43603;
    background: none;
    width: 120px;
    height: 50px;
    border-radius: 50px;
}

.main-container{
    position:absolute;
    border: solid 5px #e43603;
    background: rgba(0, 0, 0,.5);
    width: 625px;
    height: 470px;
    top: 265px;
    left: 100px;
    border-radius: 60px;
}

.buttons-group{
  position: absolute;
  right:0px;
  width: 590px;
  top: 260px;
}

.link-group{
    color:white;
    font-size: 25px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
}

.button-gr{
    background-color: #e43603;
    border-radius: 50px;
    border: none,;
    width: 450px;
    height: 50px;
    position:relative;
    margin: 24px;
}
