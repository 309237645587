.contacts-section {
    background:
        linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
        linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 20%),
        linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 20%),
        url("../../../../src/components/assets/images/background.svg") no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    scrollbar-color: rgb(193 49 0 / 80%) transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    footer {
        display: flex;
        background-color: var(--color-orange);
        width: 100%;
        height: clamp(60px, 6.4vw, 100px);
        justify-content: center;
        align-items: center;
    }


    .more-arrow {
        transition: all 1s;

        &:hover {
            color: var(--color-orange);
            transform: translateY(-5%);
        }
    }

    .more-arrow::after {
        content: "";
        mask: url("../../assets/icons/arrow_more_inf_tiny.svg") no-repeat;
        -webkit-mask: url("../../assets/icons/arrow_more_inf_tiny.svg") no-repeat;
        mask-size: contain;
        -webkit-mask-size: contain;
        width: calc(clamp(10px, 0.8vw, 20px) * var(--scale-pixel-koef));
        aspect-ratio: 1/1;
        background-color: currentColor;
        min-width: 10px;
        min-height: 10px;

    }

    .text-scale-normal {
        font-size: calc(clamp(12px, 1.2vw, 36px));
        align-content: center;
    }

    .text-scale-large {
        font-size: calc(clamp(16px, 1.6vw, 44px));
    }
}

.desktop {

    .contact-photo {
        object-fit: cover;
        object-position: center top;
        -o-object-fit: cover;
        -o-object-position: center top;
        height: 100%;
        width: 100%;
        min-width: 100%;
    }

    .wrapper {
        gap: calc(clamp(40px, 5vw, 60px) * var(--scale-pixel-koef)); //попроавить
    }

    .card {
        height: calc(clamp(500px, 30vw, 650px) * var(--scale-pixel-koef));
        background: transparent;
        border: 2px solid var(--color-orange);
        border-radius: 35px;
        overflow: hidden;
    }

    .orange-overlay::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        right: 0;
        height: 30%;
        background: linear-gradient(359.16deg, rgba(193, 49, 0, 0.7) 0.72%, rgba(0, 0, 0, 0) 101.11%) repeat left top;

    }

    .information-card {
        padding-inline: calc(var(--scale-pixel-koef)* clamp(30px, 4vw, 40px));
        padding-top: calc(var(--scale-pixel-koef) * clamp(20px, 1vw, 40px));
        padding-bottom: calc(var(--scale-pixel-koef) * clamp(20px, 1vw, 40px));


    }

}

.mobile {
    .contact-photo {
        overflow: hidden;
        border-radius: 13px;
        aspect-ratio: 1/1;

        img {
            object-fit: cover;
            object-position: center top;
            -o-object-fit: cover;
            -o-object-position: center top;
            width: 100%;
            height: 100%;

        }

    }

    .card {
        // height: calc(clamp(200px, 70vw, 600px));
        max-width: 500px;
        background: transparent;
        border: 2px solid var(--color-orange);
        border-radius: 13px;
        aspect-ratio: 1/1;
        overflow: hidden;
        padding-top: calc(var(--scale-pixel-koef) * clamp(20px, 6vw, 60px));
        padding-bottom: calc(var(--scale-pixel-koef) * clamp(20px, 6vw, 60px));
        padding-inline: calc(var(--scale-pixel-koef) * clamp(30px, 10vw, 100px));
    }

    .contact-information {
        height: auto;
        display: flex;
        flex-direction: column;
        gap: clamp(10px, 8vw, 60px);
        width: 85%;
    }

    .contact-main-text {
        width: 40%;
        color: rgba(255, 255, 255, 0.8);
    }

    .contact-desc-text {
        width: 60%;
    }

    .text-scale-normal {
        font-size: clamp(16px, 5vw, 24px);
    }

    .text-scale-large {
        font-size: clamp(20px, 6vw, 28px);
    }

    .alice-carousel__dots {
        margin: 0 !important;
    }

    .alice-carousel__prev-btn-item {
        border-radius: 50%;
        display: block;
        height: clamp(50px, 9vw, 60px);
        width: clamp(50px, 9vw, 60px);
        position: absolute;
        top: 40%;
        right: 90%;
        background:
            url("../../assets/icons/arrow.svg") no-repeat center;
        opacity: 0.8;
        transform: scaleX(-1);
        padding: 0;

        span {
            display: none;
        }
    }

    .alice-carousel__next-btn-item {
        border-radius: 50%;
        display: block;
        height: clamp(50px, 9vw, 60px);
        width: clamp(50px, 9vw, 60px);
        position: absolute;
        top: 40%;
        left: 90%;
        background:
            url("../../assets/icons/arrow.svg") no-repeat center;

        opacity: 0.8;
        padding: 0;

        span {
            display: none;
        }
    }
}

p {
    margin: 0;
    color: white;
}

a {
    color: white;
    cursor: pointer;
}

.links {
    text-decoration: none;
    color: #FFFFFFB2;
}

.mobile {
    display: none;
}

.desktop {
    display: inherit;
}

@media(max-width: 1100px) {
    .mobile {
        display: inherit;
    }

    .desktop {
        display: none;
    }
}

.line {
    margin: 90px 0 50px 0;
    height: 140px;
}