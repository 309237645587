@import "react-alice-carousel/lib/scss/alice-carousel.scss";


.news-page {
    background:
        linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
        linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 20%),
        linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 20%),
        url("../../../../src/components/assets/images/background.svg") no-repeat center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    scrollbar-color: rgb(193 49 0 / 80%) transparent;

    .alice-carousel {
        padding-left: 7.5% !important;
        padding-right: 7.5% !important;
    }

    .alice-carousel__stage-item.__active,
    .alice-carousel__stage-item.__cloned,
    .alice-carousel__stage-item.__target {
        text-align: center;
        justify-content: center;
    }


    .alice-carousel__dots {
        margin: 0 !important;
    }

    .alice-carousel__prev-btn-item {
        border-radius: 50%;
        display: block;
        min-height: 60px;
        min-width: 60px;
        position: absolute;
        top: 40%;
        left: 0;
        background:
            url("../../assets/icons/arrow.svg") no-repeat center;
        opacity: 0.8;
        transform: scaleX(-1);
        margin-left: 50px !important;
        padding: 0;

        span {
            display: none;
        }
    }

    .alice-carousel__next-btn-item {
        border-radius: 50%;
        display: block;
        min-height: 60px;
        min-width: 60px;
        position: absolute;
        top: 40%;
        right: 0%;
        background:
            url("../../assets/icons/arrow.svg") no-repeat center;

        opacity: 0.8;
        margin-right: 50px !important;
        padding: 0;

        span {
            display: none;
        }
    }
}


.news-carousel {
    margin-block: auto;
}


.swap-btn-news {
    opacity: 0.8;
}

.rotate-180 {
    transform: rotate(180deg);
}


.button-news {
    display: block;
    // margin-top: calc(var(--scale-pixel-koef) * 95px);
    // margin-bottom: calc(var(--scale-pixel-koef) * 50px);
    border: solid 2px var(--color-orange);
    background: transparent;
    width: clamp(300px, 24vw, 600px);
    max-width: 500px;
    height: calc(40px * var(--scale-pixel-koef));
    border-radius: 50px;
    justify-content: center;

}

@media (min-width: 1100px) {
    .alice-carousel__prev-btn {
        display: none !important;
    }

    .alice-carousel__next-btn {
        display: none !important;
    }

    .button-news {
        display: none;
    }
}