.page-section {
    position: absolute;
    background:linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
     url("../../../../src/components/assets/images/background.svg") no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100vw;
}

.carousel-outer {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 75%;
}

.partner-card {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 300px;
    height: 400px;

    & img {
        object-fit: contain;
        width: 100%;
        user-select: none;
        @import url(../../../../src/components/assets/images/partners/tinkoff.svg);
        -webkit-user-drag: none;
        cursor: pointer;
    }
}

.line {
    margin: 90px 0 50px 0;
    height: 140px;
}

.title {
    position: absolute;
    text-align: center;
    font-size: 80px;
    font-weight: 900;
    color: white;
    user-select: text;
}
